import * as basicScroll from 'basicscroll'
import * as Klaro from 'klaro/dist/klaro-no-translations-no-css';
import LazyLoad from 'vanilla-lazyload';
import screenfull from 'screenfull';
import Swiper from 'swiper';
import SwiperCore, { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/core';
SwiperCore.use([Pagination, Navigation, Autoplay, EffectFade]);

export default {
    init() {
        new LazyLoad({
            elements_selector: '.lazyload',
        });

        window.klaro = Klaro;
        Klaro.setup(window.klaroConfig);

        const statsSliderAutoplayTime = 4000;
        const statsSliderPaginationId = 'stats-slider-pagination'
        const swiperStatsSlider = new Swiper('.stats-slider__slider', {
            autoplay: {
                delay: 4000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            loop: true,
            pagination: {
                el: '#' + statsSliderPaginationId,
                clickable: true,
            },
            spaceBetween: 48,
        });

        const statsSliderPagination = document.getElementById('stats-slider-pagination');
        if (statsSliderPagination) {
            statsSliderPagination.style.setProperty('--pagination-animation-time', statsSliderAutoplayTime + 'ms');

            swiperStatsSlider.on('touchEnd', () => {
                statsSliderPagination.style.setProperty('--pagination-animation-time', 300 + 'ms');
            });

            statsSliderPagination.addEventListener('click', event => {
                if (event.target.classList.contains('wrapper')) {
                    statsSliderPagination.style.setProperty('--pagination-animation-time', 300 + 'ms');
                }
            });
        }

        const testimonialsSliderAutoplayTime = 5000;
        const testimonialsSliderPaginationId = 'testimonials-slider-pagination'
        const swiperTestimonialsSlider = new Swiper('.testimonials-slider__slider', {
            autoplay: {
                delay: 5000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            loop: true,
            pagination: {
                el: '#' + testimonialsSliderPaginationId,
                clickable: true,
            },
            spaceBetween: 48,
        });

        const testimonialsSliderPagination = document.getElementById('testimonials-slider-pagination');
        if (testimonialsSliderPagination) {
            testimonialsSliderPagination.style.setProperty('--pagination-animation-time', testimonialsSliderAutoplayTime + 'ms');

            swiperTestimonialsSlider.on('touchEnd', () => {
                testimonialsSliderPagination.style.setProperty('--pagination-animation-time', 300 + 'ms');
            });

            testimonialsSliderPagination.addEventListener('click', event => {
                if (event.target.classList.contains('wrapper')) {
                    testimonialsSliderPagination.style.setProperty('--pagination-animation-time', 300 + 'ms');
                }
            });
        }

        const swiperLogosSlider = new Swiper('.logos-slider__slider', {
            autoplay: {
                delay: 3000,
            },
            loop: true,
            slidesPerView: 1,
            spaceBetween: 64,
            breakpoints: {
                768: {
                    slidesPerView: 2, // 656 / 2 - 64 = 264px
                },
                1200: {
                    spaceBetween: 96,
                    slidesPerView: 3, // 1076 / 3 - 96 = 262px
                },
            },
        });
        swiperLogosSlider;

        const swiperContentSlider = new Swiper('.content-slider__slider', {
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: '.content-slider__next',
                prevEl: '.content-slider__prev',
            },
            slidesPerView: 1,
            spaceBetween: 44,
            breakpoints: {
                768: {
                    slidesPerView: 2, // 656 / 2 - 44 = 284px
                },
                992: {
                    slidesPerView: 3, // 896 / 2 - 44 = 254px
                },
                1200: {
                    slidesPerView: 3, // 1076 / 3 - 60 = 298px
                    spaceBetween: 60,
                },
                1400: {
                    slidesPerView: 4, // 1256 / 4 - 60 = 254px
                    spaceBetween: 60,
                },
            },
        });
        swiperContentSlider;

        const swiperNewsSlider = new Swiper('.news-slider__slider', {
            direction: 'vertical',
            navigation: {
                nextEl: '.news-slider__next',
                prevEl: '.news-slider__prev',
            },
            slidesPerView: 2,
            slidesPerGroup: 2,
            breakpoints: {
                576: {
                    direction: 'vertical',
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                768: {
                    direction: 'vertical',
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                992: {
                    direction: 'vertical',
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                1200: {
                    direction: 'vertical',
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                1400: {
                    direction: 'vertical',
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            },
        });
        swiperNewsSlider;

        $('.swiper-pagination--progress .swiper-pagination-bullet').each(function() {
            $(this).append('<div class="wrapper"><div class="circle left"></div><div class="circle right"></div></div>')
        });

        // 576: {
        //     slidesPerView: 2, // 476 / 2 - 48 = 190px
        // },
        // 768: {
        //     slidesPerView: 2, // 656 / 2 - 48 = 280px
        // },
        // 992: {
        //     slidesPerView: 2, // 896 / 2 - 48 = 400px
        // },
        // 1200: {
        //     slidesPerView: 3, // 1076 / 2 - 48 = 490px
        // },
        // 1400: {
        //     slidesPerView: 4, // 1256 / 2 - 48 = 580px
        // },

        // Add carets to menu
        $('.nav--main li.menu-item-has-children > a').append('<svg xmlns="http://www.w3.org/2000/svg" width="19.531" height="11.887" viewBox="0 0 19.531 11.887"><path d="M2.121,2.117l7.644,7.644,7.644-7.644" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/></svg>');
        // Add carets to menu

        // Add numbers to nav sub-items
        $('.nav--main ul.sub-menu').each(function() {
            $(this).children().each(function(index) {
                $(this).children('a').prepend('<p>' + ('0' + (index + 1)).slice(-2) + '</p>');
            });
        });
        // Add numbers to nav sub-items

        // Add container around sub-menu items and X inside
        $('.nav--main ul.sub-menu').each(function() {
            $(this).prepend('<div class="container nav__container"><div class="inner inside-menu"></div></div>');
            const container = $(this).children('.container');
            const inner = container.children('.inner');

            $(this).children().each(function() {
                if ($(this).hasClass('container')) {
                    return;
                }

                const element = $(this).detach();
                inner.append(element);
            });

            container.append('<svg class="nav__close is-btn" xmlns="http://www.w3.org/2000/svg" width="48.242" height="48.242" viewBox="0 0 48.242 48.242"><line x1="43" y2="44" transform="translate(2 2.121)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/><line x1="43" y2="44" transform="translate(46.121 2.621) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>');
        });
        // Add container around sub-menu items and X inside

        // Opening the mobile menu
        $('.menu-button').on('click', function () {
            $('.menu-button').toggleClass('open');
            $( 'body' ).toggleClass( 'mobile-menu-open' );

            var text = $('.menu-button').attr('aria-label');
            if (text == 'Open mobile navigation menu') {
                $('.menu-button').attr('aria-label', 'Close mobile navigation menu');
                $('.menu-button').attr('aria-expanded', true);
            } else {
                $('.menu-button').attr('aria-label', 'Open mobile navigation menu');
                $('.menu-button').attr('aria-expanded', false);
            }
        });
        // Opening the mobile menu

        // Opening/closing the header menu dropdowns
        $('nav .nav--main > .menu-item-has-children > a').on('click', function (event) {
            event.preventDefault();

            if ($(this).parent().siblings('.show').length !== 0) {
                $(this).parent().siblings().removeClass('show');
                $(this).parent().siblings().find('.sub-menu').removeClass('show');
            } else {
                $('.header__background').toggleClass('show');
            }

            $(this).parent().toggleClass('show');
            $(this).next().toggleClass('show');
        });

		$('nav .nav--main > .sub-menu.show > a').on('click', function (event) {
            event.preventDefault();

            let $menuItem = $(this);

            $menuItem.addClass('active');

            if ($menuItem.find('.sub-menu').length !== 0) {
                $menuItem.find('.sub-menu').removeClass('show');
                $menuItem.find('.sub-menu').removeClass('show');
            } else {
                $('.header__background').toggleClass('show');
            }

            $menuItem.find('.sub-menu:first-child').toggleClass('show');
        });

            if ($(window).width() > 1200) {
                $(document).on('mouseenter', '.nav--main li > .sub-menu li.menu-item-has-children > a', function () {
                    let $menuItem = $(this);
                    $menuItem.parent('li').addClass('active');
                });

                $(document).on('mouseleave', '.nav--main li > .sub-menu li.active', function () {

                    let $menuItem = $(this);
                    $menuItem.removeClass('active');
                });
            }

        // Opening the header menu dropdowns

        // Closing the header menu dropdowns
        $('.nav__close').on('click', function () {
            $(this).parent().parent().toggleClass('show').parent().toggleClass('show');
            $('.header__background').toggleClass('show');
        });
        // Closing the header menu dropdowns

        // Detect elements coming into view and add class
        var observer;
        const ivElements = document.querySelectorAll('.iv');
        observer = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('iv-loaded');
                }
            });
        }, { threshold: [0.3] });

        ivElements.forEach(function(image) {
            observer.observe(image);
        });
        // Detect elements coming into view and add class

        // Play video when poster is clicked,
        // fullscreen if on compatible small device,
        // change hovered cursor depending on video state
        const videos = document.querySelectorAll('video');

        if (videos.length > 0) {
            videos.forEach(video => {
                const videoPoster = video.nextElementSibling;

                videoPoster.addEventListener('click', () => {
                    if (!video.paused) {
                        window.videoPlaying = false;
                        document.body.classList.add('cursor--btn');
                        document.body.classList.remove('cursor--pause');
                        console.log('test-0')
                        return;
                    }

                    const smallScreenSize = window.innerWidth <= 768;

                    if (smallScreenSize && screenfull.isEnabled) {
                        screenfull.request(video);
                    }

                    video.setAttribute('controls', true);
                    video.classList.add('hide-poster');

                    setTimeout(function() {
                        video.play();
                        window.videoPlaying = true;
                        document.body.classList.add('cursor--pause');
                        document.body.classList.remove('cursor--btn');
                    }, 100)
                });

                videoPoster.addEventListener('mouseover', videoMouseOver);
                videoPoster.addEventListener('mouseout', videoMouseOut);

                video.addEventListener('click', () => {
                    if (!video.paused) {
                        window.videoPlaying = false;
                        document.body.classList.add('cursor--play');
                        document.body.classList.remove('cursor--pause');
                        return;
                    }

                    const smallScreenSize = window.innerWidth <= 768;

                    if (smallScreenSize && screenfull.isEnabled) {
                        screenfull.request(video);
                    }

                    video.setAttribute('controls', true);
                    video.classList.add('hide-poster');

                    setTimeout(function() {
                        video.play();
                        window.videoPlaying = true;
                        document.body.classList.add('cursor--pause');
                        document.body.classList.remove('cursor--play');
                    }, 100)
                });

                video.addEventListener('mouseover', videoMouseOver);
                video.addEventListener('mouseout', videoMouseOut);
            });
        }

        function videoMouseOver() {
            if (typeof window.videoPlaying !== 'undefined' && window.videoPlaying) {
                document.body.classList.add('cursor--pause');
            } else {
                document.body.classList.add('cursor--btn');
            }
            document.body.classList.add('cursor--video');
        }

        function videoMouseOut() {
            document.body.classList.remove('cursor--btn');
            document.body.classList.remove('cursor--play');
            document.body.classList.remove('cursor--pause');
            document.body.classList.remove('cursor--video');
        }
        // Play video when poster is clicked, fullscreen if on compatible small device

        // Parallax images
        const parallaxAmount = 128;
        const parallaxHalf = parallaxAmount / 2;
        let parallaxElements = document.querySelectorAll('.resp-img--parallax');

        parallaxElements.forEach(element => {
            element.style.setProperty('--parallax-amount', parallaxAmount + 'px');

            let instance = basicScroll.create({
                elem: element,
                from: 'top-bottom',
                to: 'bottom-top',
                direct: true,
                props: {
                    '--parallax-current': {
                        from: '-' + parallaxHalf + 'px',
                        to: parallaxHalf + 'px',
                    },
                },
            })
            instance.start();
        });
        // Parallax images

        // Show areas
        $('.areas__area-name').on('click', function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
                return;
            }

            $('.areas__area-name').removeClass('open');
            $(this).addClass('open');
        });
        // Show areas

        // Show Opportunities
        $('.opportunities__opportunity').on('click', function () {
            if ($(this).hasClass('active')) {
                return;
            }

            const index = $(this).data('opportunity');

            $('.opportunities__opportunity.active').removeClass('active');
            $('.opportunities__details.active').removeClass('active');
            $('.opportunities__details').filter('[data-opportunity="' + index + '"]').addClass('active');
            $(this).addClass('active');
        });
        // Show Opportunities

        // If cookie notice is showing, darken background until cookies are accepted
        const cookies = document.querySelector('.cn-body');

        if (cookies) {
            document.body.classList.add('cookie-notice-active');

            const btn = document.querySelector('.cn-buttons');

            if (btn) {
                btn.addEventListener('click', () => {
                    document.body.classList.remove('cookie-notice-active');
                });
            }

            const popup = document.querySelector('.cn-ok');

            if (popup) {
                popup.addEventListener('click', () => {
                    const footerBtn = document.querySelector('.cm-footer-buttons');

                    footerBtn.addEventListener('click', () => {
                        document.body.classList.remove('cookie-notice-active');
                    });
                });
            }
        }
        // If cookie notice is showing, darken background until cookies are accepted

        // Show fixed header on scroll and focus, hide submenu after scroll
        const fixedHeader = document.getElementById('fixed-header');
        const headerHeight = 140;

        if (fixedHeader) {
            const initialScrolled = window.pageYOffset || document.documentElement.scrollTop;

            if (initialScrolled >= headerHeight) {
                fixedHeader.classList.add('active');
            }

            window.addEventListener('scroll', () => {
                const scrolled = window.pageYOffset || document.documentElement.scrollTop;

                if (scrolled >= headerHeight * 2) {
                    $('.sub-menu').removeClass('show');
                    $('.header__background').removeClass('show');
                }

                if (scrolled >= headerHeight) {
                    fixedHeader.classList.add('active');
                    return;
                }

                fixedHeader.classList.remove('active');
            });

            fixedHeader.addEventListener('focusin', () => {
                fixedHeader.classList.add('active');
            });

            fixedHeader.addEventListener('focusout', () => {
                const scrolled = window.pageYOffset || document.documentElement.scrollTop;

                if (scrolled < headerHeight) {
                    fixedHeader.classList.remove('active');
                }
            });
        }
        // Show fixed header on scroll and focus

        // Fixed header scroll to top arrow button
        const scrollButton = document.getElementById('scroll-button');

        if (scrollButton) {
            scrollButton.addEventListener('click', () => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        }
        // Fixed header scroll to top arrow button

        // Show share post icons
        const sharePanel = document.getElementById('panel-share');
        const shareBtn = document.getElementById('news-share');
        const sharePanelCloseBtn = document.getElementById('panel-close');

        if (sharePanel && shareBtn) {
            shareBtn.addEventListener('click', () => {
                sharePanel.classList.toggle('active');
            });
        }

        if (sharePanel && sharePanelCloseBtn) {
            sharePanelCloseBtn.addEventListener('click', () => {
                sharePanel.classList.remove('active');
            })
        }
        // Show share post icons

        // Cursor interaction
        // const cursor = document.querySelector('#cursor');

        // document.addEventListener('mousemove', function(e) {
        //     cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
        // });

        let btnElements = [];
        btnElements.push.apply(btnElements, document.querySelectorAll('.is-btn'));
        btnElements.push.apply(btnElements, document.querySelectorAll('.brand'));
        btnElements.push.apply(btnElements, document.querySelectorAll('.btn'));
        btnElements.push.apply(btnElements, document.querySelectorAll('.menu-button'));
        btnElements.push.apply(btnElements, document.querySelectorAll('.swiper-button'));
        btnElements.push.apply(btnElements, document.querySelectorAll('.swiper-pagination-bullet'));
        btnElements.push.apply(btnElements, document.querySelectorAll('a'));
        btnElements.push.apply(btnElements, document.querySelectorAll('button'));
        btnElements.push.apply(btnElements, document.querySelectorAll('input'));
        btnElements.push.apply(btnElements, document.querySelectorAll('select'));

        if (btnElements.length > 0) {
            btnElements.forEach(element => {
                element.addEventListener('mouseover', () => {
                    if (element.classList.contains('swiper-button-disabled')) {
                        document.body.classList.remove('cursor--btn');
                        return;
                    }

                    document.body.classList.add('cursor--btn');
                });

                element.addEventListener('mouseout', () => {
                    document.body.classList.remove('cursor--btn');
                });
            });
        }

        let grabElements = [];
        grabElements.push.apply(grabElements, document.querySelectorAll('.is-grab'));

        if (grabElements.length > 0) {
            grabElements.forEach(element => {
                element.addEventListener('mouseover', () => {
                    document.body.classList.add('cursor--grab');
                });

                element.addEventListener('mouseout', () => {
                    document.body.classList.remove('cursor--grab');
                });
            });
        }

        const swiperButtons = document.querySelectorAll('.swiper-button');

        if (swiperButtons.length > 0) {
            swiperButtons.forEach(element => {
                element.addEventListener('click', () => {
                    if (element.classList.contains('swiper-button-disabled')) {
                        document.body.classList.remove('cursor--btn');
                    }
                });
            });
        }
        // Cursor interaction

        // Add Privacy policy link to Contact page form
        const contactForm = document.querySelector('.contact-hero__form-wrapper');
        const contactFormSpan = document.querySelector('.wpcf7-list-item-label');

        if (contactForm && contactFormSpan) {
            const privacyPolicyLink = document.createElement('a');
            privacyPolicyLink.setAttribute('href','/privacy-policy/');
            privacyPolicyLink.innerText = 'See Privacy Policy';
            contactFormSpan.appendChild(privacyPolicyLink);
        }
        // Add Privacy policy link to Contact page form

        // Copy link
        const copyButton = document.getElementById('copy-link');
        const tooltip = document.getElementById('copy-tooltip');

        if (copyButton) {
            copyButton.addEventListener('click', () => {
                const copyURL = copyButton.dataset.url;
                navigator.clipboard.writeText(copyURL);

                if (tooltip) {
                    tooltip.innerText = 'Link Copied!';
                }
            })

            copyButton.addEventListener('mouseenter', () => {
                const copyURL = copyButton.dataset.url;

                navigator.clipboard.readText()
                .then((clipText) => {
                    if (clipText != copyURL && tooltip) {
                        tooltip.innerText = 'Copy Link';
                    }
                })
                .catch(() => {
                    tooltip.innerText = 'Copy Link';
                });
            })
        }
        // Copy link

        // Swap Areas on about page
        const areas = document.getElementsByClassName('areas__area-name');
        const regions = document.getElementsByClassName('areas__region');

        if (areas.length > 0 && regions.length > 0) {
            areas.forEach(area => {
                area.addEventListener('click', () => {
                    const svgRegions = document.getElementsByClassName(area.dataset.area);

                    if (svgRegions.length === 0) {
                        return;
                    }

                    regions.forEach(region => {
                        region.classList.remove('active');
                    });

                    svgRegions.forEach(region => {
                        region.classList.add('active');
                    });
                });
            });
        }
        // Swap Areas on about page

        // Language Switcher
        const langSwitchWrappers = document.getElementsByClassName('lang-item');

        if (langSwitchWrappers.length > 0) {
            langSwitchWrappers.forEach(langSwitchWrapper => {
                if (!langSwitchWrapper.classList.contains('current-lang')) {
                    return;
                }

                const langSwitch = langSwitchWrapper.firstChild;

                langSwitch.addEventListener('click', event => {
                    event.preventDefault();
                    let newLangWrapper;
                    const currentLang = langSwitch.getAttribute('lang');

                    if (currentLang === 'cy') {
                        newLangWrapper = document.querySelector('.lang-item-en');
                    } else {
                        newLangWrapper = document.querySelector('.lang-item-cy');
                    }

                    const newURL = newLangWrapper.firstChild.getAttribute('href');
                    window.location.href = newURL;
                });
            });
        }
        // Language Switcher
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
